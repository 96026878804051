import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <img
        src={require("./namen.jpg")}
        alt="Namen uit namen"
        style={{ width: "100%" }}
      />
    </div>
  );
}

export default App;
